import React from "react"
import PrivacyText from "../components/privacy-policy-text"
import { graphql } from 'gatsby'
import SEO from "../components/seo"
import TopMenu from "../components/topmenu"
import CookiesPopup from "../components/cookies-popup"
import Footer from "../components/footer"


function PrivacyPolicyPageTemplate({data, pageContext}){
    const slug = data.page.slug;
    const page = data.page.frontmatter;
    
    return(
        <section className="bot-page">
                <SEO
                title="Home"
                description={page.seodescription}
                keywords={[`Embedded`, `Sulautetut`, `sulautettu`, `Tickingbot`, `software`, `consultant`, `ohjelmistokonsultti`, `Test automation`, `testiautomaatio`, `testausautomaatio`]} 
                />
                <TopMenu 
                language={pageContext.language} 
                languages={pageContext.languages} 
                slug={slug} translation={pageContext.translation}
                jobs={data.index.frontmatter.jobs}
                listJobs={data.jobs.nodes}
                services={data.index.frontmatter.services.list}
                settings={data.settings.childMdx.frontmatter}
                ></TopMenu>
                <div className="bot-page__in bot-section-text ">
                  <div className="container-900">
                  <PrivacyText page={page} settings={data.settings.childMdx.frontmatter} />
                  </div>
                </div>
                <Footer settings={data.settings.childMdx.frontmatter}></Footer>
                <CookiesPopup translation={pageContext.translation}></CookiesPopup>

        </section>

    )

}
export const pageQuery = graphql`
  query PrivacyById($id: String!, $language: String!) {
    page: mdx( id: { eq: $id }) {
      id
      slug
      frontmatter {
        title
        seodescription
        description
        contact
      }
    }
    jobs: allMdx(filter: {frontmatter: {contentType: {eq: "job"}, language: {eq: $language}}}) {
      nodes {
        fields{
          slug
        }
        frontmatter {
          title
          slug
          enabled
        }
      }
    }
    index: mdx(frontmatter: {templateKey: {eq: "templates/index"}, language: {eq: $language}}) {
      frontmatter {
        services {
          list: services {
            title
          }
        }
        jobs {
          list{
            position
          }
        }
      }
    }
    settings: file(sourceInstanceName: {eq: "settings"}, name: {eq: "settings"}) {
      childMdx {
        frontmatter {
          companyname
          sitename
          address
          address2
          phone
          linkedin
          email
        }
      }
    }
  }
`


export default PrivacyPolicyPageTemplate